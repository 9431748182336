import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { getCognitoUser, getCurrentCognitoUser } from './cognito-user';

export const cognitoGetCurrentUserSession = async () => {
  const cognitoUser = await getCurrentCognitoUser();

  if (cognitoUser == null) return undefined;

  return new Promise<CognitoUserSession>(((resolve, reject) => {
    cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
      if (err) {
        console.error('get session err', err);
        reject(err);
        return;
      }
      resolve(session);
    });
  }));
};

export const cognitoGetUserSession = async (username: string, user?: CognitoUser) => {
  const cognitoUser = user ?? await getCognitoUser(username);
  if (cognitoUser == null) return undefined;

  return new Promise<CognitoUserSession>(((resolve, reject) => {
    cognitoUser.getSession((err, session: CognitoUserSession) => {
      if (err) {
        console.error('get session err', err);
        reject(err);
        return;
      }
      resolve(session);
    });
  }));
};

export default { cognitoGetCurrentUserSession, cognitoGetUserSession };
