import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import LuxonUtils from '@date-io/luxon';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/utils/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import ReactPixel from 'react-facebook-pixel';
import UploadErrorNotification from './components/UploadErrorNotification';
import { GroupsAuthProvider } from './contexts/GroupAuthContext';

const jss = create({ plugins: [...jssPreset().plugins] });
const history = createBrowserHistory();

ReactPixel.init('105898440281053');
ReactPixel.pageView();

const App: FC = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <GroupsAuthProvider>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <SnackbarProvider
              dense
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              maxSnack={5}
            >
              <Router history={history}>
                <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  <GoogleAnalytics />
                  <CookiesNotification />
                  <UploadErrorNotification />
                  {/* <SettingsNotification /> */}
                  {renderRoutes(routes)}
                </AuthProvider>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </GroupsAuthProvider>
  );
};

export default App;
