import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import useGroupAuth from 'src/utils/hooks/useGroupAuth';
import NavBar from './NavBar';
import TopBar from './TopBar';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 250, // 256
      transition: 'padding-left .3s ease'
    },
  },
  navClose: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 60, // 256
      transition: 'padding-left .3s ease'
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { groupAuth: { userIsInInventoryGroup, userIsInOrderGroup } } = useGroupAuth();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [isNavMinimized, setNavMinimize] = useState<boolean>(localStorage.getItem('is_nav_minimized') === 'true');

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => {
          localStorage.setItem('is_nav_minimized', 'true');
          setMobileNavOpen(true);
        }}
        isNavMinimized={isNavMinimized}
      />
      <NavBar
        userIsInInventoryGroup={userIsInInventoryGroup}
        userIsInOrderGroup={userIsInOrderGroup}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        onNavSizeToggle={() => { localStorage.setItem('is_nav_minimized', `${!isNavMinimized}`); setNavMinimize(!isNavMinimized); }}
      />
      {isNavMinimized ? (
        <div className={classes.navClose}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
