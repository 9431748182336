import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import * as Sentry from '@sentry/browser';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import { api } from 'src/data/api';
import { useHistory } from 'react-router';
import ReactPixel from 'react-facebook-pixel';
import cognitoLogin from 'src/data/auth/login';
import cognitoSignOut from 'src/data/auth/signout';
import { getCurrentCognitoUser } from 'src/data/auth/cognito-user';
import { CognitoUser } from 'amazon-cognito-identity-js';
import useGroupAuth from 'src/utils/hooks/useGroupAuth';
import establishCognitoSession from 'src/data/auth/establish-cognito-session';
import WebsocketApi from 'src/data/ws-api';
import type { MarketplaceSubscriptions } from '../../../cdk/src/lib/rds';
import { GetStartedStepper } from '../types/getStartedStepper';

interface AuthState {
  isAdminSession: boolean;
  isInitialised: boolean;
  isAuthenticated: boolean;
  hasActiveSubscription: boolean;
  enableAutoOrdering: boolean;
  user: User | null;
  getStartedState: GetStartedStepper;
  userAttributes;
  cognitoUser: CognitoUser;
  marketplaceSubscriptions: MarketplaceSubscriptions;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (inp: {companyName: string; name: string; email: string;phone: string; password: string;utmSource?: string}) => Promise<void>;
  setAOEnabled: (enableAutoOrdering: boolean) => Promise<void>;
  setNewPassword: (cognitoUser, userAttributes, newPassword: string) => Promise<void>;
  establishSession: (authenticationResult: AWS.CognitoIdentityServiceProvider.AuthenticationResultType) => Promise<void>;

  closeGetStartedStepper: () => void;
  setGetStartedStepCompleted: (step: string) => void;
  toggleConfetii: (show: boolean) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALIZE';
  payload: {
    isAdminSession: boolean;
    isAuthenticated: boolean;
    getStartedState: GetStartedStepper;
    hasActiveSubscription: boolean;
    marketplaceSubscriptions: MarketplaceSubscriptions;
    enableAutoOrdering: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    hasActiveSubscription: boolean;
    marketplaceSubscriptions: MarketplaceSubscriptions;
    enableAutoOrdering: boolean;
    isAdminSession: boolean;
  };
};

type EstablishSessionAction = {
  type: 'ESTABLISH_SESSION';
  payload: {
    user: User;
    hasActiveSubscription: boolean;
    enableAutoOrdering: boolean;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
    marketplaceSubscriptions: MarketplaceSubscriptions;
  };
};

type SetAOStatusAction = {
  type: 'SET_AO_STATUS';
  payload: {
    enableAutoOrdering: boolean;
  };
};

type SetNewPasswordAction = {
  type: 'SET_NEW_PASSWORD';
  payload: {
    userAttributes;
    cognitoUser;
  };
};

type setGetStartedStepCompletedAction = {
  type: 'SET_GET_STARTED_STEP_COMPLETED';
  payload: {
    steps;
  };
};

type toggleConfetiiAction = {
  type: 'TOGGLE_CONFETII';
  payload: {
    show;
  };
};

type updateGetStartedStateAction = {
  type: 'UPDATE_GET_STARTED_STATE';
  payload: {
    activeStep;
    activeStepIndex;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | SetAOStatusAction
  | SetNewPasswordAction
  | EstablishSessionAction
  | setGetStartedStepCompletedAction
  | toggleConfetiiAction
  | updateGetStartedStateAction;

const initialGetStartedState = {
  activeStep: null,
  activeStepIndex: 0,
  showConfetti: false,
  steps: null
};

const initialStateList = {
  add_channel: false,
  add_product: false,
  config_automation: false,
  config_repricing: false,
};

const initialAuthState: AuthState = {
  isAdminSession: false,
  isAuthenticated: false,
  isInitialised: false,
  hasActiveSubscription: false,
  enableAutoOrdering: false,
  user: null,
  getStartedState: initialGetStartedState,
  userAttributes: null,
  marketplaceSubscriptions: {
    AMAZONCOM: {
      active: false, max_active_listings_of_plan: 0, stripe_subscription_id: null, subscription_status: 'unsubscribed'
    },
    WALMARTCOM: {
      active: false, max_active_listings_of_plan: 0, stripe_subscription_id: null, subscription_status: 'unsubscribed'
    },
    FACEBOOKCOM: {
      active: false, max_active_listings_of_plan: 0, stripe_subscription_id: null, subscription_status: 'unsubscribed'
    },
    SHOPIFYCOM: {
      active: false, max_active_listings_of_plan: 0, stripe_subscription_id: null, subscription_status: 'unsubscribed'
    },
    WHOLESALE: {
      active: false, max_active_listings_of_plan: 0, stripe_subscription_id: null, subscription_status: 'unsubscribed'
    }
  },
  cognitoUser: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALIZE': {
      const {
        isAuthenticated, user, hasActiveSubscription, enableAutoOrdering, marketplaceSubscriptions, isAdminSession, getStartedState
      } = action.payload;

      return {
        ...state,
        isAdminSession,
        isAuthenticated,
        isInitialised: true,
        hasActiveSubscription,
        marketplaceSubscriptions,
        enableAutoOrdering,
        getStartedState,
        user
      };
    }
    case 'LOGIN': {
      const {
        user, hasActiveSubscription, marketplaceSubscriptions, enableAutoOrdering, isAdminSession,
      } = action.payload;

      return {
        ...state,
        isAdminSession,
        isAuthenticated: true,
        hasActiveSubscription,
        marketplaceSubscriptions,
        enableAutoOrdering,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        enableAutoOrdering: false,
      };
    }
    case 'SET_GET_STARTED_STEP_COMPLETED': {
      const {
        steps
      } = action.payload;
      return {
        ...state,
        getStartedState: {
          ...state.getStartedState,
          steps
        },
      };
    }
    case 'TOGGLE_CONFETII': {
      const {
        show
      } = action.payload;
      return {
        ...state,
        getStartedState: {
          ...state.getStartedState,
          showConfetti: show,
        },
      };
    }
    case 'UPDATE_GET_STARTED_STATE': {
      const {
        activeStep, activeStepIndex
      } = action.payload;
      return {
        ...state,
        getStartedState: {
          ...state.getStartedState,
          activeStep,
          activeStepIndex,
        },
      };
    }
    case 'REGISTER': {
      const { user, marketplaceSubscriptions } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        hasActiveSubscription: true,
        marketplaceSubscriptions,
        enableAutoOrdering: false,
        user
      };
    }
    case 'SET_AO_STATUS': {
      const { enableAutoOrdering } = action.payload;

      return {
        ...state,
        enableAutoOrdering,
      };
    }
    case 'SET_NEW_PASSWORD': {
      const { cognitoUser, userAttributes } = action.payload;

      return {
        ...state,
        cognitoUser,
        userAttributes,
      };
    }
    case 'ESTABLISH_SESSION': {
      const { user, hasActiveSubscription, enableAutoOrdering } = action.payload;

      return {
        ...state,
        isAdminSession: true, // establish session is called from admin panel -> admin session is always true
        isAuthenticated: true,
        hasActiveSubscription,
        enableAutoOrdering,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  setAOEnabled: () => Promise.resolve(),
  setNewPassword: () => Promise.resolve(),
  establishSession: () => Promise.resolve(),
  closeGetStartedStepper: () => {},
  setGetStartedStepCompleted: () => {},
  toggleConfetii: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  const { refreshGroupAuth } = useGroupAuth();

  const login = async (emailInput: string, password: string) => {
    const email = emailInput?.toLowerCase();
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect');

    let result = null;
    try {
      result = await cognitoLogin(email, password);
    } catch (error) {
      /** If cognito user is not found, check for DB entries and create a new one */
      if (error.code === 'UserNotFoundException') {
        console.log('Cognito user not found. Searching for db match');
        const registered = await api.syncDBUserWithCognito(email, password);
        if (!registered) throw new Error(`No account with email ${email} found.`);
        console.log('DB match found and mathing cognito user created');
        result = await cognitoLogin(email, password);
        console.log('cognito login result', result);
      } else if (error.code === 'NotAuthorizedException') {
        throw error;
      }
    }

    if (result.firstLogin) {
      dispatch({
        type: 'SET_NEW_PASSWORD',
        payload: {
          cognitoUser: result.cognitoUser,
          userAttributes: result.userAttributes,
        }
      });
      history.push('/set-password/');
    }

    if (result.idToken) {
      const {
        enableAutoOrdering, marketplaceSubscriptions, is_amazon_ext_used,
      } = await api.getCurrentUserAndCompanySubscription({});
      const hasActiveSubscription = marketplaceSubscriptions?.AMAZONCOM?.active || marketplaceSubscriptions?.WALMARTCOM?.active || marketplaceSubscriptions?.SHOPIFYCOM?.active; // subscriptionStatus === 'active' || subscriptionStatus === 'trial';

      const user: User = {
        avatar: '', email, id: result.user.userId, name: result.user.userName, hasActiveSubscription, marketplaceSubscriptions, is_amazon_ext_used, companyId: result.user.companyId
      };

      // Track user on Sentry
      Sentry.setUser({ id: user.id, username: user.name, email: user.email });

      localStorage.setItem('username', email);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.removeItem('isAdminSession');
      WebsocketApi.forceConnect();

      await refreshGroupAuth();

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          isAdminSession: false,
          enableAutoOrdering,
          marketplaceSubscriptions,
          hasActiveSubscription,
        }
      });
      try {
        await api.updateUserLastLogin({});
      } catch (error) {
        console.error('Error updating user last login', error);
      }
      if (redirect) history.push(redirect + url.hash);
      else history.push('/app/orders');
    }
  };

  const establishSession = async (authenticationResult: AWS.CognitoIdentityServiceProvider.AuthenticationResultType) => {
    const session = await establishCognitoSession(authenticationResult);
    if (!session.getIdToken().getJwtToken()) return;

    const accessToken = await session.getAccessToken().getJwtToken();
    const idToken = await session.getIdToken().getJwtToken();
    const userDataPayload = session.getIdToken().decodePayload();
    const { email, companyId } = userDataPayload;

    localStorage.setItem('idToken', idToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('username', email);
    localStorage.setItem('isAdminSession', 'true');
    WebsocketApi.forceConnect();

    const {
      subscriptionStatus, enableAutoOrdering, is_amazon_ext_used,
    } = await api.getCurrentUserAndCompanySubscription({});
    const hasActiveSubscription = subscriptionStatus === 'active' || subscriptionStatus === 'trial';

    const user: User = {
      avatar: '',
      email,
      id: userDataPayload['custom:userId'],
      name: userDataPayload.nickname,
      hasActiveSubscription,
      is_amazon_ext_used,
      companyId,
    };

    localStorage.setItem('user', JSON.stringify(user));

    await refreshGroupAuth();

    dispatch({
      type: 'ESTABLISH_SESSION',
      payload: {
        user,
        enableAutoOrdering,
        hasActiveSubscription,
      }
    });

    history.push('/app/orders');
  };

  const setNewPassword = async (cognitoUser: CognitoUser, userAttributes: unknown, newPassword: string) => {
    await cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: async () => {
        await login(cognitoUser.getUsername(), newPassword);
      },
      onFailure: (err) => {
        console.error(err);
        return err;
      }
    });
  };

  const logout = async () => {
    Sentry.setUser(null);
    await cognitoSignOut();
    localStorage.removeItem('username');
    localStorage.removeItem('user');
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    localStorage.removeItem('tags');
    localStorage.removeItem('isAdminSession');
    dispatch({ type: 'LOGOUT' });
  };

  const updateGetStartedState = async (stepper) => {
    const stepperKeys = Object.keys(stepper).sort();
    const firstActive = stepperKeys.find((key: string) => !stepper[key]);
    const activeStep = firstActive || null;
    const activeStepIndex = stepperKeys.indexOf(firstActive) === -1 ? 0 : stepperKeys.indexOf(firstActive);
    dispatch({ type: 'UPDATE_GET_STARTED_STATE', payload: { activeStep, activeStepIndex } });
    api.updateGetStartedState({ getStartedState: stepper });
  };

  const closeGetStartedStepper = async () => {
    const stepper = {
      add_channel: true,
      add_product: true,
      config_automation: true,
      config_repricing: true,
    };

    dispatch({
      type: 'SET_GET_STARTED_STEP_COMPLETED',
      payload: {
        steps: stepper
      }
    });

    await updateGetStartedState(stepper);
  };

  const toggleConfetii = async (show: boolean) => {
    dispatch({ type: 'TOGGLE_CONFETII', payload: { show } });
  };

  const setGetStartedStepCompleted = async (step: string) => {
    if (state.getStartedState.steps) {
      const stepper = { ...state.getStartedState.steps, ...{ [step]: true } };
      dispatch({ type: 'SET_GET_STARTED_STEP_COMPLETED', payload: { steps: stepper } });

      if (!state.getStartedState.steps[step] && stepper[step]) {
        await toggleConfetii(true);
      }
      await updateGetStartedState(stepper);
    }
  };

  const setAOEnabled = async (enableAutoOrdering: boolean) => {
    dispatch({ type: 'SET_AO_STATUS', payload: { enableAutoOrdering } });
  };

  const register = async ({
    companyName, name, email: emailInput, phone, password, utmSource
  }: {companyName: string; name: string; email: string; phone: string;password: string;utmSource?: string}) => {
    const email = emailInput?.toLowerCase();
    const { user: { userId, companyId }, stripeCustomerId, marketplaceSubscriptions } = await api.register({
      email, password, name, companyName, phone, utmSource
    });
    const user: User = {
      email, name, id: userId, avatar: name.substring(0, 1), hasActiveSubscription: true, is_amazon_ext_used: false, companyId,
    };

    await cognitoLogin(email, password);
    localStorage.setItem('username', user.email);

    // Tapfiliate
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).tap('trial', stripeCustomerId);
    ReactPixel.track('StartTrial');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).gtag('event', 'conversion', {
      send_to: 'AW-823881216/4O9sCNXE75IBEIDc7YgD',
    });

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        marketplaceSubscriptions,
      }
    });
    history.push('/app/account');
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const idToken = localStorage.getItem('idToken');

        const cognitoUser = await getCurrentCognitoUser();

        if (cognitoUser != null) {
          cognitoUser.getSession((err, session) => {
            if (err) {
              console.error('get session err', err);
              return;
            }
            localStorage.setItem('idToken', session.idToken.jwtToken);
            localStorage.setItem('accessToken', session.accessToken.jwtToken);
          });
        }

        if (idToken) {
          const {
            user, subscriptionStatus, enableAutoOrdering, marketplaceSubscriptions, getStartedState, is_amazon_ext_used,
          } = await api.getCurrentUserAndCompanySubscription({});
          const hasActiveSubscription = subscriptionStatus === 'active' || subscriptionStatus === 'trial';

          const stepper = getStartedState === null ? initialStateList : getStartedState;
          const stepperKeys = Object.keys(stepper).sort();
          const firstActive = stepperKeys.find((key: string) => !stepper[key]);
          const activeStep = firstActive || null;
          const activeStepIndex = stepperKeys.indexOf(firstActive) === -1 ? 0 : stepperKeys.indexOf(firstActive);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAdminSession: localStorage.getItem('isAdminSession') === 'true',
              isAuthenticated: true,
              hasActiveSubscription,
              enableAutoOrdering,
              marketplaceSubscriptions,
              getStartedState: {
                ...initialGetStartedState,
                ...{
                  steps: Object.values(stepper).filter((i) => !i).length ? stepper : null,
                  activeStep,
                  activeStepIndex
                }
              },
              user: {
                avatar: '',
                email: user.email,
                hasActiveSubscription,
                id: user.id,
                name: user.name,
                is_amazon_ext_used,
                companyId: user.company_id,
              }
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAdminSession: false,
              isAuthenticated: false,
              enableAutoOrdering: false,
              getStartedState: initialGetStartedState,
              hasActiveSubscription: false,
              marketplaceSubscriptions: initialAuthState.marketplaceSubscriptions,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAdminSession: false,
            isAuthenticated: false,
            enableAutoOrdering: false,
            getStartedState: initialGetStartedState,
            hasActiveSubscription: false,
            marketplaceSubscriptions: initialAuthState.marketplaceSubscriptions,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        setAOEnabled,
        setNewPassword,
        establishSession,
        closeGetStartedStepper,
        setGetStartedStepCompleted,
        toggleConfetii,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
