import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Subscription } from 'rxjs';
import WebsocketApi from 'src/data/ws-api';

export default function UploadErrorNotification(): JSX.Element {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const subs: Subscription[] = [];
    subs.push(WebsocketApi.messages$('product/updated-failed').subscribe((message) => {
      enqueueSnackbar(`The following products have failed to be bulk updated: \n${message.failedProducts.map((fp) => (
        `- ${fp.sku}: (${fp.error})\n`
      ))}`, {
        variant: 'error',
        persist: true,
        action: (key) => (
          <button type="button" onClick={() => closeSnackbar(key)}>
            <i className="fas fa-times" />
          </button>
        )
      });
    }));
    return () => { subs.forEach((sub) => sub.unsubscribe()); };
  }, []);

  return (
    <>
    </>
  );
}
