import * as Rx from 'rxjs';
import { AnyWebsocketMessage, GetWebsocketMessageType, WebsocketMessageType } from './ws-message.interfaces';

class WebsocketApi {
  private ws: WebSocket;

  private messageSubject$ = new Rx.Subject<AnyWebsocketMessage>();

  private static instance: WebsocketApi;

  private static getInstance() {
    if (!WebsocketApi.instance) {
      WebsocketApi.instance = new WebsocketApi();
    }
    return WebsocketApi.instance;
  }

  public static messages$<T extends WebsocketMessageType>(messageType: T) {
    return WebsocketApi.getInstance().messageSubject$.pipe(Rx.filter((m) => messageType === m.type)) as Rx.Observable<GetWebsocketMessageType<T>>;
  }

  public static forceConnect() {
    WebsocketApi.getInstance().connect();
  }

  private constructor() {
    this.connect();
  }

  private send(message, callback = undefined) {
    this.waitForConnection(() => {
      this.ws.send(message);
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 1000);
  }

  private waitForConnection(callback, interval) {
    if (this.ws.readyState === 1) {
      callback();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      // optional: implement backoff for interval here
      setTimeout(() => {
        that.waitForConnection(callback, interval);
      }, interval);
    }
  }

  private connect() {
    const accessToken = localStorage.getItem('accessToken');

    const wsUrl = process.env.REACT_APP_WEBSOCKET_API_URL?.includes('/prod/') ? process.env.REACT_APP_WEBSOCKET_API_URL : `${process.env.REACT_APP_WEBSOCKET_API_URL}/prod/`;
    this.ws = new WebSocket(wsUrl);
    this.ws.onopen = async (event) => {
      console.log('ws onopen', event);
      // this.ws.send(JSON.stringify({ accessToken }));
      this.send(JSON.stringify({ accessToken }));
    };
    this.ws.onmessage = (event) => {
      console.log('ws onmessage', event);
      try {
        const message = JSON.parse(event.data) as AnyWebsocketMessage;
        this.messageSubject$.next(message);
      } catch (err) {
        console.log('ERROR: ws onmessage non-json message received: ', event.data);
      }
    };
    this.ws.onerror = (event) => {
      console.log('ws onerror', event);
    };
    this.ws.onclose = (event) => {
      console.log('ws onclose', event);
      setTimeout(() => {
        this.connect();
      }, 1000);
    };
  }
}

export default WebsocketApi;
