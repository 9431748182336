import type { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    // '::-webkit-scrollbar': {
    //   height: '4px', /* height of horizontal scrollbar ← You're missing this */
    //   width: '4px', /* width of vertical scrollbar */
    //   border: '1px solid #d5d5d5'
    // }
    '::-webkit-scrollbar': {
      height: '4px',
      width: '4px',
      background: 'gray',
    },
    '::-webkit-scrollbar-thumb:horizontal': {
      background: '#000',
      borderRadius: '10px'
    }
  }
}));

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
