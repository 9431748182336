import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
  makeStyles,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import type { Theme } from 'src/theme';
import { transcode } from 'buffer';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  open?: boolean;
  isNavMinimized?: boolean;
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 400,
    '&.depth-0': {
      '& $title': {
        fontWeight: 500,
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '&.depth-0 $label': {
        paddingLeft: 10,
      },
    },
  },
  label: {
    transition: 'all .3s ease',
    '& svg': {
      marginRight: 10,
    },
    '& span': {
      marginRight: 0,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  navMinimize: {
    justifyContent: 'center',
    '& $title': {
      opacity: 0,
      transition: 'opacity .5s ease',
    },
    '& svg': {
      marginRight: 0,
    }
  },
  title: {
    whiteSpace: 'nowrap',
    marginRight: 'auto',
    opacity: 1,
    transition: 'opacity .5s ease',
  },
  active: {
    background: 'linear-gradient(118deg, rgb(38 185 154), rgba(38, 185, 154, 0.7))',
    boxShadow: '0 0 10px 1px rgb(38 185 154 / 70%)',
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: 500,
      color: 'white',
    },
    '&:hover': {
      background: 'linear-gradient(118deg, rgb(38 185 154), rgba(38, 185, 154, 0.7))',
      color: 'white',
      '& $label': {
        paddingLeft: 10,
      },
    },
    '& $icon': {
      color: 'white'
    }
  },
  miniLink: {
    minWidth: '50px',
    padding: '10px 3px'
  }
}));

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  isNavMinimized,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  if (isNavMinimized) {
    paddingLeft = 3;
  }

  const style = { paddingLeft };

  const isExternalLink = href.includes('http');

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          classes={{ label: classes.label }}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >

      {isNavMinimized ? (
        <Hidden mdDown>
          <Tooltip title={title} placement="right" className={classes.navMinimize}>
            <Button
              activeClassName={classes.active}
              className={clsx(classes.buttonLeaf, `depth-${depth}`, classes.miniLink)}
              classes={{ label: classes.label }}
              component={!isExternalLink ? RouterLink : undefined}
              to={!isExternalLink ? href : undefined}
              href={isExternalLink ? href : undefined}
              target={isExternalLink ? '_blank' : undefined}
              exact
              style={style}
            >
              {Icon && (
                <Icon
                  className={classes.icon}
                  size="20"
                />
              )}
              {Info && <Info />}
            </Button>
          </Tooltip>
        </Hidden>
      ) : (
        <Hidden mdDown>
          <Tooltip title={title} placement="right">
            <Button
              activeClassName={classes.active}
              className={clsx(classes.buttonLeaf, `depth-${depth}`)}
              classes={{ label: classes.label }}
              component={!isExternalLink ? RouterLink : undefined}
              to={!isExternalLink ? href : undefined}
              href={isExternalLink ? href : undefined}
              target={isExternalLink ? '_blank' : undefined}
              exact
              style={style}
            >
              {Icon && (
                <Icon
                  className={classes.icon}
                  size="20"
                />
              )}
              <span className={classes.title}>
                {title}
              </span>
              {Info && <Info />}
            </Button>
          </Tooltip>
        </Hidden>
      )}

      <Hidden lgUp>
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            // classes={{ label: classes.label }}
          component={!isExternalLink ? RouterLink : undefined}
          to={!isExternalLink ? href : undefined}
          href={isExternalLink ? href : undefined}
          target={isExternalLink ? '_blank' : undefined}
          exact
          style={style}
        >
          {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {Info && <Info />}
        </Button>
      </Hidden>

    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  isNavMinimized: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
