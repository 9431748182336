import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  dark?: boolean;
}

const Logo: FC<LogoProps> = ({ dark }) => (
  <img
    alt="Logo"
    src={dark ? '/static/logo.png' : '/static/oag-logo-white.png'}
    width="50"
  />
);

export default Logo;
