import React, {
  createContext,
  useEffect,
  useState
} from 'react';
import type { FC, ReactNode } from 'react';
import { cognitoGetCurrentUserSession } from 'src/data/auth/get-session';

interface GroupAuthState {
  userIsInInventoryGroup: boolean;
  userIsInOrderGroup: boolean;
  userIsInAdminGroup: boolean;
  userIsInOwnerGroup: boolean;
}

export interface GroupAuthContextValue {
  groupAuth: GroupAuthState;
  refreshGroupAuth: () => Promise<void>;
}

interface GroupAuthProviderProps {
  groupsAuth?: GroupAuthState;
  children?: ReactNode;
}

const defaultGroupAuth: GroupAuthState = {
  userIsInInventoryGroup: null,
  userIsInOrderGroup: null,
  userIsInAdminGroup: null,
  userIsInOwnerGroup: null
};

const GroupAuthContext = createContext<GroupAuthContextValue>({
  groupAuth: defaultGroupAuth,
  refreshGroupAuth: () => Promise.resolve(),
});

export const GroupsAuthProvider: FC<GroupAuthProviderProps> = ({ groupsAuth, children }) => {
  const [currentGroupAuth, setCurrentGroupAuth] = useState<GroupAuthState>(groupsAuth || defaultGroupAuth);

  const refreshGroupAuth = async () => {
    await cognitoGetCurrentUserSession()
      .then((session) => {
        if (session) {
          const cognitoGroups = session.getIdToken().payload['cognito:groups'];
          const groupAuth = {
            userIsInInventoryGroup: cognitoGroups.includes('inventory'),
            userIsInOrderGroup: cognitoGroups.includes('order'),
            userIsInAdminGroup: cognitoGroups.includes('admin'),
            userIsInOwnerGroup: cognitoGroups.includes('owner'),
          };
          setCurrentGroupAuth(groupAuth);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    async function load() {
      await refreshGroupAuth();
    }
    load();
  }, []);

  return (
    <GroupAuthContext.Provider
      value={{
        groupAuth: currentGroupAuth,
        refreshGroupAuth,
      }}
    >
      {children}
    </GroupAuthContext.Provider>
  );
};

export const GroupAuthConsumer = GroupAuthContext.Consumer;

export default GroupAuthContext;
