import {
  CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { getCognitoUser } from './cognito-user';
import { cognitoGetUserSession } from './get-session';

const establishCognitoSession = async (authenticationResult) => {
  const IdToken = new CognitoIdToken({ IdToken: authenticationResult.IdToken });
  const AccessToken = new CognitoAccessToken({ AccessToken: authenticationResult.AccessToken });
  const RefreshToken = new CognitoRefreshToken({ RefreshToken: authenticationResult.RefreshToken });
  const userSession = new CognitoUserSession({ IdToken, AccessToken, RefreshToken });
  const username = userSession.getIdToken().payload.email;
  const cognitoUser = getCognitoUser(username);
  cognitoUser.setSignInUserSession(userSession);
  return cognitoGetUserSession(null, cognitoUser);
};

export default establishCognitoSession;
