import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import useGroupAuth from 'src/utils/hooks/useGroupAuth';
import useAuth from '../utils/hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { groupAuth: { userIsInInventoryGroup, userIsInOrderGroup } } = useGroupAuth();
  const unauthorizedMessage = 'You are not authorized to access this page.';
  const { isAuthenticated, marketplaceSubscriptions, isAdminSession } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  if (!isAuthenticated) {
    enqueueSnackbar('Unauthenticated - please login.');
    return <Redirect to="/login" />;
  }

  const hasActiveSubscription = marketplaceSubscriptions?.AMAZONCOM?.active || marketplaceSubscriptions?.WALMARTCOM?.active || marketplaceSubscriptions?.FACEBOOKCOM?.active || marketplaceSubscriptions?.SHOPIFYCOM?.active;
  if (!hasActiveSubscription && !isAdminSession && !window.location.pathname.includes('app/account/billing')) {
    enqueueSnackbar('No active subscription - please subscribe first.');
    return <Redirect to="/app/account/billing" />;
  }

  if (window.location.pathname.includes('/products')) {
    if (userIsInOrderGroup && !userIsInInventoryGroup) {
      enqueueSnackbar(unauthorizedMessage);
      return <Redirect to="/app/orders" />;
    }
  }

  if (window.location.pathname.includes('/orders')) {
    if (userIsInInventoryGroup && !userIsInOrderGroup) {
      enqueueSnackbar(unauthorizedMessage);
      return <Redirect to="/app/products" />;
    }
  }

  if (window.location.pathname.includes('/account')) {
    if (userIsInInventoryGroup || userIsInOrderGroup) {
      enqueueSnackbar(unauthorizedMessage);
      return <Redirect to="/app/orders" />;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
