/* eslint-disable class-methods-use-this */
// import { LoginInputType, LoginResultType } from '../../../cdk/src/lambda/frontend-api/auth/login';
import pRetry from 'p-retry';
import { WebsocketMessageMarketplaceProductInfoFetched } from './ws-message.interfaces';
import { SyncDBUserWithCognitoInputType, SyncDBUserWithCognitoResultType } from '../../../cdk/src/lambda/frontend-api/auth/sync-db-user-with-cognito';
import { RegisterInputType, RegisterResultType } from '../../../cdk/src/lambda/frontend-api/auth/register';
import { InviteUserInputType, InviteUserResultType } from '../../../cdk/src/lambda/frontend-api/auth/invite-user';
import { AdminPanelLoginInputType, AdminPanelLoginResultType } from '../../../cdk/src/lambda/frontend-api/auth/admin-panel-login';
import { LambdaInformedApiKeyInputType, LambdaInformedApiKeyResultType } from '../../../cdk/src/lambda/frontend-api/account/informed-apikey';
import { LambdaIntegrationSettingsInputType, LambdaIntegrationSettingsResultType } from '../../../cdk/src/lambda/frontend-api/account/walmart-credentials';
import { ShopifySettingsLambdaInputType, ShopifyIntegrationSettingsResponse } from '../../../cdk/src/lambda/frontend-api/account/shopify-credentials';
import { ShipstationSettingsLambdaInputType, ShipstationAutomationSettingsResponse } from '../../../cdk/src/lambda/frontend-api/account/shipstation-automation-credentials';
import { AmazonSCVerifyCredentialsLambdaInput, AmazonSCVerifyCredentialsLambdaResultType } from '../../../cdk/src/lambda/marketplaces/amazon-us-sc/amazon-credentials-api/save-amazon-credentials';
import { AmazonBuyingAccountsLambdaInputType, AmazonBuyingAccountsLambdaResultType } from '../../../cdk/src/lambda/frontend-api/account/amazon-buying-account';
import { LambdaGetIntegrationSettingsInputType, LambdaGetIntegrationSettingsResultType } from '../../../cdk/src/lambda/frontend-api/account/get-integration-settings';
import { LambdaGetAutomationSettingsInputType, LambdaGetAutomationSettingsResultType } from '../../../cdk/src/lambda/frontend-api/account/get-automation-settings';
import { LambdaGetSourceSettingsInputType, LambdaGetSourceSettingsResultType } from '../../../cdk/src/lambda/frontend-api/account/get-source-settings';
import { LambdaSetSourceSettingsInputType, LambdaSetSourceSettingsResultType } from '../../../cdk/src/lambda/frontend-api/account/set-source-settings';
import { LambdaUpdateAutomationSettingsInputType, LambdaUpdateAutomationSettingsResultType } from '../../../cdk/src/lambda/frontend-api/account/update-automation-settings';
import { LambdaUpdateEmailForwardingInputType, LambdaUpdateEmailForwardingResultType } from '../../../cdk/src/lambda/frontend-api/account/update-email-forwarding';
import { LambdaRepricingSettingSellerIdInputType, LambdaRepricingSettingSellerIdResultType } from '../../../cdk/src/lambda/frontend-api/account/update-seller-ids';
import { LambdaGetUserAndSubscriptionInputType, LambdaGetUserAndSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/users/get-user-and-subscription';
import { LambdaGetUserEmailNotificationSettingsInputType, LambdaGetUserEmailNotificationSettingsResultType } from '../../../cdk/src/lambda/frontend-api/users/get-user-email-notification-settings';
import { LambdaGetCompanySubUsersInputType, LambdaGetCompanySubUsersResultType } from '../../../cdk/src/lambda/frontend-api/users/get-company-subusers';
import { LambdaUpdateSubUserGroupsInputType, LambdaUpdateSubUserGroupsResultType } from '../../../cdk/src/lambda/frontend-api/users/update-subuser-groups';
import { LambdaDeleteSubUserInputType, LambdaDeleteSubUserResultType } from '../../../cdk/src/lambda/frontend-api/users/delete-subuser';
import { LambdaUpdateUserLastLoginInputType, LambdaUpdateUserLastLoginResultType } from '../../../cdk/src/lambda/frontend-api/users/update-user-last-login';
import { LambdaUpdateUserEmailNotificationSettingsInputType, LambdaUpdateUserEmailNotificationSettingsResultType } from '../../../cdk/src/lambda/frontend-api/users/update-user-email-notification-settings';
import { LambdaToggleAutoOrderingInputType, LambdaToggleAutoOrderingResultType } from '../../../cdk/src/lambda/frontend-api/auto-ordering/toggle-auto-ordering';
import { LambdaGetTrackingCreditsInputType, LambdaGetTrackingCreditsResultType } from '../../../cdk/src/lambda/frontend-api/billing/get-tracking-credits';
import { LambdaGetReliablepartsKeyInputType, LambdaGetReliablepartsKeyResultType } from '../../../cdk/src/lambda/frontend-api/account/get-reliableparts-key';
import { LambdaSetReliablepartsKeyInputType, LambdaSetReliablepartsKeyResultType } from '../../../cdk/src/lambda/frontend-api/account/set-reliableparts-key';

import { LambdaAmazonStartOAuthInputType, LambdaAmazonStartOAuthResultType } from '../../../cdk/src/lambda/marketplaces/amazon-us/amazon-credentials-api/start-amazon-spapi-oauth';
import { AmazonSaveCredentialsLambdaInput, AmazonSaveCredentialsLambdaResultType } from '../../../cdk/src/lambda/marketplaces/amazon-us/amazon-credentials-api/save-amazon-spapi-credentials';
import { LambdaShopifyStartOAuthInputType, LambdaShopifyStartOAuthResultType } from '../../../cdk/src/lambda/marketplaces/shopify/shopify-credentials-api/start-shopify-oauth';
import { ShopifyPostOAuthCallbackLambdaInputType, ShopifyPostOAuthCallbackLambdaResultType } from '../../../cdk/src/lambda/marketplaces/shopify/shopify-credentials-api/post-oauth-callback';
import { FacebookEnableLambdaInputType, FacebookEnableLambdaResultType } from '../../../cdk/src/lambda/frontend-api/account/facebook-enable';

import { MarketplaceListingInfoLambdaInputType } from '../../../cdk/src/lambda/frontend-api/products/marketplace-listing-info';
import { CrawlItemInputType, CrawlItemResultType } from '../../../cdk/src/lambda/frontend-api/crawler/crawl-item';
import { BulkUpdateListingInputType, BulkUpdateListingResultType } from '../../../cdk/src/lambda/frontend-api/products/bulk-update-listing';
// import { UpdateListingStatusInputType, UpdateListingStatusResultType } from '../../../cdk/src/lambda/frontend-api/products/update-listing-status';
import { UpdateSourceForSkuLambdaInput, UpdateSourceForSkuLambdaResult } from '../../../cdk/src/lambda/frontend-api/products/update-source-for-sku';
import { CreateScrapedListingLambdaInput, CreateScrapedListingLambdaResult } from '../../../cdk/src/lambda/frontend-api/products/create-listing-single';
import { ExportProductsLambdaInputType, ExportProductsLambdaResultType } from '../../../cdk/src/lambda/frontend-api/products/export-products';
import { ExportShippingCostOverrideCSVTemplateLambdaInputType, ExportShippingCostOverrideCSVTemplateLambdaResultType } from '../../../cdk/src/lambda/frontend-api/products/export-shippingcostoverride-csv-template';
import { GetProductsPaginatedInputType, GetProductsPaginatedResultType } from '../../../cdk/src/lambda/frontend-api/products/get-products';
import { GetSourcesByExistingProductsInputType, GetSourcesByExistingProductsResultType } from '../../../cdk/src/lambda/frontend-api/products/get-sources-by-existing-products';
import { BulkUpdateEnableRepricingLambdaInputType, BulkUpdateEnableRepricingResultType } from '../../../cdk/src/lambda/frontend-api/products/bulk-update-enable-repricing';
import { BulkUpdateHandlingDaysInputType, BulkUpdateHandlingDaysResultType } from '../../../cdk/src/lambda/frontend-api/products/bulk-update-handlingdays';
import { BulkAppendTagsLambdaInputType, BulkAppendTagsResultType } from '../../../cdk/src/lambda/frontend-api/products/bulk-append-product-tags';
import { BulkRemoveTagsLambdaInputType, BulkRemoveTagsResultType } from '../../../cdk/src/lambda/frontend-api/products/bulk-remove-product-tags';
import { GetCompanyTagsLambdaInputType, GetCompanyTagsResultType } from '../../../cdk/src/lambda/frontend-api/products/get-company-product-tags';
import { AddManualProductLogInputType, AddManualProductLogResultType } from '../../../cdk/src/lambda/frontend-api/products/add-manual-product-log';
import { UpdateManualProductLogInputType, UpdateManualProductLogResultType } from '../../../cdk/src/lambda/frontend-api/products/update-manual-product-log';
import { RemoveManualProductLogInputType, RemoveManualProductLogResultType } from '../../../cdk/src/lambda/frontend-api/products/remove-manual-product-log';
import { ListProductLogInputType, ListProductLogResultType } from '../../../cdk/src/lambda/frontend-api/products/list-product-log';

import { BulkListerLambdaInputType, BulkListerLambdaResultType } from '../../../cdk/src/lambda/frontend-api/uploads/csv-upload';
import { GetErrorFileLambdaInput, GetErrorFileLambdaResult } from '../../../cdk/src/lambda/frontend-api/uploads/error-file';
import { GetUploadsLambdaInpuType, GetUploadsLambdaResultType } from '../../../cdk/src/lambda/frontend-api/uploads/get-uploads';
import { GetDownloadsLambdaInpuType, GetDownloadsLambdaResultType } from '../../../cdk/src/lambda/frontend-api/products/get-downloads';
import { GetDownloadFileLambdaInputType, GetDownloadFileLambdaResultType } from '../../../cdk/src/lambda/frontend-api/products/get-download-file';

import { UpdateOrderLineRefundInputType, UpdateOrderLineRefundResultType } from '../../../cdk/src/lambda/frontend-api/orders/update-order-line-item-refunds';
import { UpdateOrderLineStatusInputType, UpdateOrderLineStatusResultType } from '../../../cdk/src/lambda/frontend-api/orders/update-order-line-item-status';
import { AddNoteToOrderInputType, AddNoteToOrderResultType } from '../../../cdk/src/lambda/frontend-api/orders/add-order-line-item-notes';
import { UpdateNoteToOrderInputType, UpdateNoteToOrderResultType } from '../../../cdk/src/lambda/frontend-api/orders/update-order-line-item-notes';
import { DeleteNoteToOrderInputType, DeleteNoteToOrderResultType } from '../../../cdk/src/lambda/frontend-api/orders/delete-order-line-item-notes';
import { AddSourceOrderInputType, AddSourceOrderResultType } from '../../../cdk/src/lambda/frontend-api/orders/add-source-order';
import { UserOpenedPlaceOrderModalInputType, UserOpenedPlaceOrderModalResultType } from '../../../cdk/src/lambda/frontend-api/orders/user-opened-place-order-modal';
import { UpdateSourceOrderInputType, UpdateSourceOrderResultType } from '../../../cdk/src/lambda/frontend-api/orders/update-source-order';
import { RemoveSourceOrderInputType, RemoveSourceOrderResultType } from '../../../cdk/src/lambda/frontend-api/orders/remove-source-order';
import { AddTrackingNrInputType, AddTrackingNrResultType } from '../../../cdk/src/lambda/frontend-api/orders-trackingnrs/add-tracking-number';
import { RemoveTrackingNumberInputType, RemoveTrackingNumberResultType } from '../../../cdk/src/lambda/frontend-api/orders-trackingnrs/remove-tracking-number';
import { UpdateTrackingNumberInputType, UpdateTrackingNumberResultType } from '../../../cdk/src/lambda/frontend-api/orders-trackingnrs/update-tracking-number';
import { ScheduleOrderForAutoOrderingInputType, ScheduleOrderForAutoOrderingResultType } from '../../../cdk/src/lambda/frontend-api/auto-ordering/trigger-autoordering-for-order';
import { ExportOrdersLambdaInputType, ExportOrdersLambdaResultType } from '../../../cdk/src/lambda/frontend-api/orders/export-orders';
import { SearchOrdersInputType, SearchOrdersResultType } from '../../../cdk/src/lambda/frontend-api/orders/search-orders';

import { GetSubscriptionInputType, GetSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/get-subscription';
import { UpdateWalmartSubscriptionInputType, UpdateWalmartSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/update-subscription';
import { UpdateAmazonSubscriptionInputType, UpdateAmazonSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/update-subscription-amazon';
import { UpdateFacebookSubscriptionInputType, UpdateFacebookSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/update-subscription-facebook';
import { UpdateShopifySubscriptionInputType, UpdateShopifySubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/update-subscription-shopify';
import { UpdateWholesaleSubscriptionInputType, UpdateWholesaleSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/update-subscription-wholesale';
import { UpdatePaymentMethodInputType, UpdatePaymentMethodResultType } from '../../../cdk/src/lambda/frontend-api/billing/update-payment-method';
import { CancelSubscriptionInputType, CancelSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/cancel-subscription';
import { CancelAmazonSubscriptionInputType, CancelAmazonSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/cancel-amazon-subscription';
import { CancelShopifySubscriptionInputType, CancelShopifySubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/cancel-shopify-subscription';
import { CancelWholesaleSubscriptionInputType, CancelWholesaleSubscriptionResultType } from '../../../cdk/src/lambda/frontend-api/billing/cancel-wholesale-subscription';
import { BuyTrackingCreditsInputType, BuyTrackingCreditsResultType } from '../../../cdk/src/lambda/frontend-api/billing/buy-tracking-credits';

import { ToggleRepricingForProductInputType, ToggleRepricingForProductResultType } from '../../../cdk/src/lambda/frontend-api/repricing/toggle-repricing-for-product';
import { SaveRepricingSettingsInputType, SaveRepricingSettingsResultType } from '../../../cdk/src/lambda/frontend-api/repricing/update-repricing-settings';
import { GetRepricingSettingsInputType, GetRepricingSettingsResultType } from '../../../cdk/src/lambda/frontend-api/repricing/get-repricing-settings';
import { CreateCustomIntegrationInputType, CreateCustomIntegrationResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/create-custom-integration';
import { ListCustomIntegrationInputType, ListCustomIntegrationResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/list-custom-integration';
import { DeleteCustomIntegrationInputType, DeleteCustomIntegrationResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/delete-custom-integration';
import { UpdateCustomIntegrationInputType, UpdateCustomIntegrationResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/update-custom-integration';
import { SearchCustomIntegrationInputType, SearchCustomIntegrationResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/search-custom-integration';
// import { SaveAmazonRepricingSettingsInputType, SaveAmazonRepricingSettingsResultType } from '../../../cdk/src/lambda/frontend-api/repricing/update-amazon-repricing-settings';
// import { CreateScrapedCustomListingLambdaInput, CreateScrapedCustomListingLambdaResult } from '../../../cdk/src/lambda/frontend-api/products/create-custom-listing-single';
import { UpdateCustomIntegrationStatusLambdaEnv, UpdateCustomIntegrationStatusResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/update-custom-integration-status';
import { ExportCustomIntegrationInputType, ExportCustomIntegrationLambdaResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/export-custom-integration';
import { ExportCustomIntegrationTemplateInputType, ExportCustomIntegrationTemplateLambdaResultType } from '../../../cdk/src/lambda/frontend-api/custom-integration/export-custom-integration-listing-template';
import { SyncFacebookOrdersInputType, SyncFacebookOrdersResultType } from '../../../cdk/src/lambda/frontend-api/facebook/sync-facebook-orders';
import { SaveFacebookRepricingSettingsInputType, SaveFacebookRepricingSettingsResultType } from '../../../cdk/src/lambda/frontend-api/repricing/update-facebook-repricing-settings';
import { SaveShopifyRepricingSettingsInputType, SaveShopifyRepricingSettingsResultType } from '../../../cdk/src/lambda/frontend-api/repricing/update-shopify-repricing-settings';

import { cognitoGetCurrentUserSession } from './auth/get-session';
import { cognitoSignOut } from './auth/signout';
import { LambdaUpdateGetStartedStateInputType, LambdaUpdateGetStartedStateResultType } from '../../../cdk/src/lambda/frontend-api/users/update-get-started-state';

/**
 * API instance, wraps frontend API requests.
 * Authentication is done via JWT cookie so it doesn't need any frontend code.
 */
class API {
  // ------------------------------------
  // Auth
  // ------------------------------------

  public async syncDBUserWithCognito(email: string, password: string) {
    const syncDBUserWithCognitoResult = await this.doRequest<SyncDBUserWithCognitoInputType, SyncDBUserWithCognitoResultType>('sync-db-user-with-cognito', 'POST', { email, password });
    return syncDBUserWithCognitoResult.registered;
  }

  public async register(inp: RegisterInputType) {
    const result = await this.doRequest<RegisterInputType, RegisterResultType>('register', 'POST', inp);
    console.log('cognito register', result);
    return { user: result.user, stripeCustomerId: result.stripeCustomerId, marketplaceSubscriptions: result.marketplaceSubscriptions };
  }

  public async inviteUser(inp: InviteUserInputType) {
    const result = await this.doRequest<InviteUserInputType, InviteUserResultType>('invite-user', 'POST', inp);
    return { user: result.user };
  }

  public async adminPanelLogin(inp: AdminPanelLoginInputType) {
    return this.doRequest<AdminPanelLoginInputType, AdminPanelLoginResultType>('admin-panel-login', 'POST', inp);
  }

  // ------------------------------------
  // User Management
  // ------------------------------------

  public async getCompanySubUsers(inp: LambdaGetCompanySubUsersInputType) {
    return this.doRequest<LambdaGetCompanySubUsersInputType, LambdaGetCompanySubUsersResultType>('get-company-subusers', 'POST', inp);
  }

  public async updateSubUserGroups(inp: LambdaUpdateSubUserGroupsInputType) {
    return this.doRequest<LambdaUpdateSubUserGroupsInputType, LambdaUpdateSubUserGroupsResultType>('update-subuser-groups', 'POST', inp);
  }

  public async updateGetStartedState(inp: LambdaUpdateGetStartedStateInputType) {
    return this.doRequest<LambdaUpdateGetStartedStateInputType, LambdaUpdateGetStartedStateResultType>('update-get-started-state', 'POST', inp);
  }

  public async deleteSubUser(inp: LambdaDeleteSubUserInputType) {
    return this.doRequest<LambdaDeleteSubUserInputType, LambdaDeleteSubUserResultType>('delete-subuser', 'POST', inp);
  }

  public async getCurrentUserAndCompanySubscription(inp: LambdaGetUserAndSubscriptionInputType) {
    return this.doRequest<LambdaGetUserAndSubscriptionInputType, LambdaGetUserAndSubscriptionResultType>('get-user-and-subscription', 'POST', inp);
  }

  public async updateUserLastLogin(inp: LambdaUpdateUserLastLoginInputType) {
    return this.doRequest<LambdaUpdateUserLastLoginInputType, LambdaUpdateUserLastLoginResultType>('update-user-last-login', 'POST', inp);
  }

  public async getUserEmailNotificationSettings(inp: LambdaGetUserEmailNotificationSettingsInputType) {
    return this.doRequest<LambdaGetUserEmailNotificationSettingsInputType, LambdaGetUserEmailNotificationSettingsResultType>('get-user-email-notification-settings', 'POST', inp);
  }

  public async updateUserEmailNotificationSettings(inp: LambdaUpdateUserEmailNotificationSettingsInputType) {
    return this.doRequest<LambdaUpdateUserEmailNotificationSettingsInputType, LambdaUpdateUserEmailNotificationSettingsResultType>('update-user-email-notification-settings', 'POST', inp);
  }

  // ------------------------------------
  // Settings
  // ------------------------------------

  public async updateInformedApiKey(inp: LambdaInformedApiKeyInputType) {
    return this.doRequest<LambdaInformedApiKeyInputType, LambdaInformedApiKeyResultType>('informed-apikey', 'POST', inp);
  }

  public async getSourceSettings(inp: LambdaGetSourceSettingsInputType) {
    return this.doRequest<LambdaGetSourceSettingsInputType, LambdaGetSourceSettingsResultType>('get-source-settings', 'POST', inp);
  }

  public async setSourceSettings(inp: LambdaSetSourceSettingsInputType) {
    return this.doRequest<LambdaSetSourceSettingsInputType, LambdaSetSourceSettingsResultType>('set-source-settings', 'POST', inp);
  }

  public async getReliablepartsSettings(inp: LambdaGetReliablepartsKeyInputType) {
    return this.doRequest<LambdaGetReliablepartsKeyInputType, LambdaGetReliablepartsKeyResultType>('get-reliableparts-key', 'POST', inp);
  }

  public async setReliablepartsSettings(inp: LambdaSetReliablepartsKeyInputType) {
    return this.doRequest<LambdaSetReliablepartsKeyInputType, LambdaSetReliablepartsKeyResultType>('set-reliableparts-key', 'POST', inp);
  }

  public async getIntegrationSettings(inp: LambdaGetIntegrationSettingsInputType) {
    return this.doRequest<LambdaGetIntegrationSettingsInputType, LambdaGetIntegrationSettingsResultType>('get-integration-settings', 'POST', inp);
  }

  public async updateWalmartIntegrationSettings(inp: LambdaIntegrationSettingsInputType) {
    return this.doRequest<LambdaIntegrationSettingsInputType, LambdaIntegrationSettingsResultType>('walmart-credentials', 'POST', inp);
  }

  public async updateShopifyIntegrationSettings(inp: ShopifySettingsLambdaInputType) {
    return this.doRequest<ShopifySettingsLambdaInputType, ShopifyIntegrationSettingsResponse>('shopify-credentials', 'POST', inp);
  }

  public async updateShipstationAutomationSettings(inp: ShipstationSettingsLambdaInputType) {
    return this.doRequest<ShipstationSettingsLambdaInputType, ShipstationAutomationSettingsResponse>('shipstation-automation-credentials', 'POST', inp);
  }

  public async saveAmazonSCIntegrationSettings(inp: AmazonSCVerifyCredentialsLambdaInput) {
    return this.doRequest<AmazonSCVerifyCredentialsLambdaInput, AmazonSCVerifyCredentialsLambdaResultType>('save-amazon-credentials', 'POST', inp);
  }

  public async saveAmazonSpapiIntegrationSettings(inp: AmazonSaveCredentialsLambdaInput) {
    return this.doRequest<AmazonSaveCredentialsLambdaInput, AmazonSaveCredentialsLambdaResultType>('save-amazon-spapi-credentials', 'POST', inp);
  }

  public async getAutomationSettings(inp: LambdaGetAutomationSettingsInputType) {
    return this.doRequest<LambdaGetAutomationSettingsInputType, LambdaGetAutomationSettingsResultType>('get-automation-settings', 'POST', inp);
  }

  public async updateAmazonBuyingAccount(inp: AmazonBuyingAccountsLambdaInputType) {
    return this.doRequest<AmazonBuyingAccountsLambdaInputType, AmazonBuyingAccountsLambdaResultType>('amazon-buying-account', 'POST', inp);
  }

  public async updateTrackingAutomationSettings(inp: LambdaUpdateAutomationSettingsInputType) {
    return this.doRequest<LambdaUpdateAutomationSettingsInputType, LambdaUpdateAutomationSettingsResultType>('update-automation-settings', 'POST', inp);
  }

  public async updateEmailForwardingEnabled(inp: LambdaUpdateEmailForwardingInputType) {
    return this.doRequest<LambdaUpdateEmailForwardingInputType, LambdaUpdateEmailForwardingResultType>('update-email-forwarding', 'POST', inp);
  }

  public async enableFacebookIntegration(inp: FacebookEnableLambdaInputType) {
    return this.doRequest<FacebookEnableLambdaInputType, FacebookEnableLambdaResultType>('facebook-enable', 'POST', inp);
  }

  // public async getCurrentUserAndCompanySubscription(inp: LambdaGetUserAndSubscriptionInputType) {
  //   return this.doRequest<LambdaGetUserAndSubscriptionInputType, LambdaGetUserAndSubscriptionResultType>('get-user-and-subscription', 'POST', inp);
  // }

  public async toggleAutoOrdering(inp: LambdaToggleAutoOrderingInputType) {
    return this.doRequest<LambdaToggleAutoOrderingInputType, LambdaToggleAutoOrderingResultType>('toggle-auto-ordering', 'POST', inp);
  }

  public async getTrackingCredits(inp: LambdaGetTrackingCreditsInputType) {
    return this.doRequest<LambdaGetTrackingCreditsInputType, LambdaGetTrackingCreditsResultType>('get-tracking-credits', 'POST', inp);
  }

  public async amazonStartOAuth(inp: LambdaAmazonStartOAuthInputType) {
    return this.doRequest<LambdaAmazonStartOAuthInputType, LambdaAmazonStartOAuthResultType>('start-amazon-spapi-oauth', 'POST', inp);
  }

  public async shopifyStartOAuth(inp: LambdaShopifyStartOAuthInputType) {
    return this.doRequest<LambdaShopifyStartOAuthInputType, LambdaShopifyStartOAuthResultType>('start-shopify-oauth', 'POST', inp, new AbortController().signal, 'include');
  }

  public async shopifyPostOauthCallback(inp: ShopifyPostOAuthCallbackLambdaInputType) {
    return this.doRequest<ShopifyPostOAuthCallbackLambdaInputType, ShopifyPostOAuthCallbackLambdaResultType>('post-oauth-callback', 'POST', inp);
  }

  // ------------------------------------
  // Products
  // ------------------------------------

  public async getMarketplaceListingInfo(inp: MarketplaceListingInfoLambdaInputType) {
    return this.doRequest<MarketplaceListingInfoLambdaInputType, WebsocketMessageMarketplaceProductInfoFetched>('marketplace-listing-info', 'POST', inp);
  }

  public async getSourceItemInfo(inp: CrawlItemInputType) {
    return this.doRequest<CrawlItemInputType, CrawlItemResultType[]>('crawl-item', 'POST', inp);
  }

  // public async updateListingStatus(inp: UpdateListingStatusInputType) {
  //   return this.doRequest<UpdateListingStatusInputType, UpdateListingStatusResultType>('update-listing-status', 'POST', inp);
  // }

  public updateSourceForSku(inp: UpdateSourceForSkuLambdaInput) {
    return this.doRequest<UpdateSourceForSkuLambdaInput, UpdateSourceForSkuLambdaResult>('update-source-for-sku', 'POST', inp);
  }

  public async createScrapedListing(inp: CreateScrapedListingLambdaInput) {
    return this.doRequest<CreateScrapedListingLambdaInput, CreateScrapedListingLambdaResult>('create-listing-single', 'POST', inp);
  }

  public async exportProducts(inp: ExportProductsLambdaInputType) {
    return this.doRequest<ExportProductsLambdaInputType, ExportProductsLambdaResultType>('export-products', 'POST', inp);
  }

  public async exportShippingCostOverrideTemplate(inp: ExportShippingCostOverrideCSVTemplateLambdaInputType) {
    return this.doRequest<ExportShippingCostOverrideCSVTemplateLambdaInputType, ExportShippingCostOverrideCSVTemplateLambdaResultType>('export-shippingcostoverride-csv-template', 'POST', inp);
  }

  public async getProductsPaginated(inp: GetProductsPaginatedInputType, signal: AbortSignal) {
    return this.doRequest<GetProductsPaginatedInputType, GetProductsPaginatedResultType>('get-products', 'POST', inp, signal);
  }

  public async getSourcesByExistingProducts(inp: GetSourcesByExistingProductsInputType) {
    return this.doRequest<GetSourcesByExistingProductsInputType, GetSourcesByExistingProductsResultType>('get-sources-by-existing-products', 'POST', inp);
  }

  public async bulkUpdateListings(inp: BulkUpdateListingInputType) {
    return this.doRequest<BulkUpdateListingInputType, BulkUpdateListingResultType>('bulk-update-listing', 'POST', inp);
  }

  public async bulkUpdateHandlingDays(inp: BulkUpdateHandlingDaysInputType) {
    return this.doRequest<BulkUpdateHandlingDaysInputType, BulkUpdateHandlingDaysResultType>('bulk-update-handlingdays', 'POST', inp);
  }

  public async bulkUpdateEnableRepricing(inp: BulkUpdateEnableRepricingLambdaInputType) {
    return this.doRequest<BulkUpdateEnableRepricingLambdaInputType, BulkUpdateEnableRepricingResultType>('bulk-update-enable-repricing', 'POST', inp);
  }

  public async bulkAppendTagsToListings(inp: BulkAppendTagsLambdaInputType) {
    return this.doRequest<BulkAppendTagsLambdaInputType, BulkAppendTagsResultType>('bulk-append-product-tags', 'POST', inp);
  }

  public async bulkDeleteTagsToListings(inp: BulkRemoveTagsLambdaInputType) {
    return this.doRequest<BulkRemoveTagsLambdaInputType, BulkRemoveTagsResultType>('bulk-remove-product-tags', 'POST', inp);
  }

  public async getCompanyTags(inp: GetCompanyTagsLambdaInputType) {
    return this.doRequest<GetCompanyTagsLambdaInputType, GetCompanyTagsResultType>('get-company-product-tags', 'POST', inp);
  }

  public async createProductLog(inp: AddManualProductLogInputType) {
    return this.doRequest<AddManualProductLogInputType, AddManualProductLogResultType>('add-manual-product-log', 'POST', inp);
  }

  public async updateProductLog(inp: UpdateManualProductLogInputType) {
    return this.doRequest<UpdateManualProductLogInputType, UpdateManualProductLogResultType>('update-manual-product-log', 'POST', inp);
  }

  public async removeProductLog(inp: RemoveManualProductLogInputType) {
    return this.doRequest<RemoveManualProductLogInputType, RemoveManualProductLogResultType>('remove-manual-product-log', 'POST', inp);
  }

  public async listProductLogs(inp: ListProductLogInputType) {
    return this.doRequest<ListProductLogInputType, ListProductLogResultType>('list-product-log', 'POST', inp);
  }

  // ------------------------------------
  // Uploads
  // ------------------------------------

  public async uploadBulkListings(inp: BulkListerLambdaInputType) {
    return this.doRequest<BulkListerLambdaInputType, BulkListerLambdaResultType>('csv-upload', 'POST', inp);
  }

  public async getUploads(inp: GetUploadsLambdaInpuType) {
    return this.doRequest<GetUploadsLambdaInpuType, GetUploadsLambdaResultType>('get-uploads', 'POST', inp);
  }

  public async getUploadFile(inp: GetErrorFileLambdaInput) {
    return this.doRequest<GetErrorFileLambdaInput, GetErrorFileLambdaResult>('error-file', 'POST', inp);
  }

  // ------------------------------------
  // Downloads
  // ------------------------------------

  public async getDownloads(inp: GetDownloadsLambdaInpuType) {
    return this.doRequest<GetDownloadsLambdaInpuType, GetDownloadsLambdaResultType>('get-downloads', 'POST', inp);
  }

  public async getDownloadFile(inp: GetDownloadFileLambdaInputType) {
    return this.doRequest<GetDownloadFileLambdaInputType, GetDownloadFileLambdaResultType>('get-download-file', 'POST', inp);
  }

  // ------------------------------------
  // Orders
  // ------------------------------------

  public async updateOrderLineItemRefunds(inp: UpdateOrderLineRefundInputType) {
    return this.doRequest<UpdateOrderLineRefundInputType, UpdateOrderLineRefundResultType>('update-order-line-item-refunds', 'POST', inp);
  }

  public async updateOrderLineItemsStatus(inp: UpdateOrderLineStatusInputType) {
    return this.doRequest<UpdateOrderLineStatusInputType, UpdateOrderLineStatusResultType>('update-order-line-item-status', 'POST', inp);
  }

  public async addOrderLineNotes(inp: AddNoteToOrderInputType) {
    return this.doRequest<AddNoteToOrderInputType, AddNoteToOrderResultType>('add-order-line-item-notes', 'POST', inp);
  }

  public async updateOrderLineNotes(inp: UpdateNoteToOrderInputType) {
    return this.doRequest<UpdateNoteToOrderInputType, UpdateNoteToOrderResultType>('update-order-line-item-notes', 'POST', inp);
  }

  public async deleteOrderLineNotes(inp: DeleteNoteToOrderInputType) {
    return this.doRequest<DeleteNoteToOrderInputType, DeleteNoteToOrderResultType>('delete-order-line-item-notes', 'POST', inp);
  }

  public async addTrackingNr(inp: AddTrackingNrInputType) {
    return this.doRequest<AddTrackingNrInputType, AddTrackingNrResultType>('add-tracking-number', 'POST', inp);
  }

  public async removeTrackingNr(inp: RemoveTrackingNumberInputType) {
    return this.doRequest<RemoveTrackingNumberInputType, RemoveTrackingNumberResultType>('remove-tracking-number', 'POST', inp);
  }

  public async updateTrackingNr(inp: UpdateTrackingNumberInputType) {
    return this.doRequest<UpdateTrackingNumberInputType, UpdateTrackingNumberResultType>('update-tracking-number', 'POST', inp);
  }

  public async setUserOpenedPlaceOrderModal(inp: UserOpenedPlaceOrderModalInputType) {
    return this.doRequest<UserOpenedPlaceOrderModalInputType, UserOpenedPlaceOrderModalResultType>('user-opened-place-order-modal', 'POST', inp);
  }

  public async addSourceOrder(inp: AddSourceOrderInputType) {
    return this.doRequest<AddSourceOrderInputType, AddSourceOrderResultType>('add-source-order', 'POST', inp);
  }

  public async updateSourceOrder(inp: UpdateSourceOrderInputType) {
    return this.doRequest<UpdateSourceOrderInputType, UpdateSourceOrderResultType>('update-source-order', 'POST', inp);
  }

  public async removeSourceOrder(inp: RemoveSourceOrderInputType) {
    return this.doRequest<RemoveSourceOrderInputType, RemoveSourceOrderResultType>('remove-source-order', 'POST', inp);
  }

  public async searchOrders(inp: SearchOrdersInputType, signal: AbortSignal) {
    return this.doRequest<SearchOrdersInputType, SearchOrdersResultType>('search-orders', 'POST', inp, signal);
  }

  public async exportOrders(inp: ExportOrdersLambdaInputType) {
    return this.doRequest<ExportOrdersLambdaInputType, ExportOrdersLambdaResultType>('export-orders', 'POST', inp);
  }

  public async triggerAutoOrderingForOrder(inp: ScheduleOrderForAutoOrderingInputType) {
    return this.doRequest<ScheduleOrderForAutoOrderingInputType, ScheduleOrderForAutoOrderingResultType>('trigger-autoordering-for-order', 'POST', inp);
  }

  // ------------------------------------
  // Billing
  // ------------------------------------

  public async getSubscription(inp: GetSubscriptionInputType) {
    return this.doRequest<GetSubscriptionInputType, GetSubscriptionResultType>('get-subscription', 'POST', inp);
  }

  public async updatePaymentMethod(inp: UpdatePaymentMethodInputType) {
    return this.doRequest<UpdatePaymentMethodInputType, UpdatePaymentMethodResultType>('update-payment-method', 'POST', inp);
  }

  public async updateSubscriptionWalmart(inp: UpdateWalmartSubscriptionInputType) {
    return this.doRequest<UpdateWalmartSubscriptionInputType, UpdateWalmartSubscriptionResultType>('update-subscription', 'POST', inp);
  }

  public async updateSubscriptionAmazon(inp: UpdateAmazonSubscriptionInputType) {
    return this.doRequest<UpdateAmazonSubscriptionInputType, UpdateAmazonSubscriptionResultType>('update-subscription-amazon', 'POST', inp);
  }

  public async updateSubscriptionFacebook(inp: UpdateFacebookSubscriptionInputType) {
    return this.doRequest<UpdateFacebookSubscriptionInputType, UpdateFacebookSubscriptionResultType>('update-subscription-facebook', 'POST', inp);
  }

  public async updateSubscriptionShopify(inp: UpdateShopifySubscriptionInputType) {
    return this.doRequest<UpdateShopifySubscriptionInputType, UpdateShopifySubscriptionResultType>('update-subscription-shopify', 'POST', inp);
  }

  public async updateSubscriptionWholesale(inp: UpdateWholesaleSubscriptionInputType) {
    return this.doRequest<UpdateWholesaleSubscriptionInputType, UpdateWholesaleSubscriptionResultType>('update-subscription-wholesale', 'POST', inp);
  }

  public async cancelSubscription(inp: CancelSubscriptionInputType) {
    return this.doRequest<CancelSubscriptionInputType, CancelSubscriptionResultType>('cancel-subscription', 'POST', inp);
  }

  public async cancelAmazonSubscription(inp: CancelAmazonSubscriptionInputType) {
    return this.doRequest<CancelAmazonSubscriptionInputType, CancelAmazonSubscriptionResultType>('cancel-amazon-subscription', 'POST', inp);
  }

  public async cancelFacebookSubscription(inp: CancelAmazonSubscriptionInputType) {
    return this.doRequest<CancelAmazonSubscriptionInputType, CancelAmazonSubscriptionResultType>('cancel-amazon-subscription', 'POST', inp);
  }

  public async cancelShopifySubscription(inp: CancelShopifySubscriptionInputType) {
    return this.doRequest<CancelShopifySubscriptionInputType, CancelShopifySubscriptionResultType>('cancel-shopify-subscription', 'POST', inp);
  }

  public async cancelWholesaleSubscription(inp: CancelWholesaleSubscriptionInputType) {
    return this.doRequest<CancelWholesaleSubscriptionInputType, CancelWholesaleSubscriptionResultType>('cancel-wholesale-subscription', 'POST', inp);
  }

  public async buyTrackingCredits(inp: BuyTrackingCreditsInputType) {
    return this.doRequest<BuyTrackingCreditsInputType, BuyTrackingCreditsResultType>('buy-tracking-credits', 'POST', inp);
  }

  // ------------------------------------
  // Walmart internal repricing
  // ------------------------------------

  public async toggleRepricingForProduct(inp: ToggleRepricingForProductInputType) {
    return this.doRequest<ToggleRepricingForProductInputType, ToggleRepricingForProductResultType>('toggle-repricing-for-product', 'POST', inp);
  }

  public async getRepricingSettings(inp: GetRepricingSettingsInputType) {
    return this.doRequest<GetRepricingSettingsInputType, GetRepricingSettingsResultType>('get-repricing-settings', 'POST', inp);
  }

  public async updateRepricingSettings(inp: SaveRepricingSettingsInputType) {
    return this.doRequest<SaveRepricingSettingsInputType, SaveRepricingSettingsResultType>('update-repricing-settings', 'POST', inp);
  }

  public async updateFacebookRepricingSettings(inp: SaveFacebookRepricingSettingsInputType) {
    return this.doRequest<SaveFacebookRepricingSettingsInputType, SaveFacebookRepricingSettingsResultType>('update-facebook-repricing-settings', 'POST', inp);
  }

  public async updateShopifyRepricingSettings(inp: SaveFacebookRepricingSettingsInputType) {
    return this.doRequest<SaveShopifyRepricingSettingsInputType, SaveShopifyRepricingSettingsResultType>('update-shopify-repricing-settings', 'POST', inp);
  }

  public async updateSellerIds(inp: LambdaRepricingSettingSellerIdInputType) {
    return this.doRequest<LambdaRepricingSettingSellerIdInputType, LambdaRepricingSettingSellerIdResultType>('update-seller-ids', 'POST', inp);
  }

  // ------------------------------------
  // Amazon internal repricing
  // ------------------------------------

  // public async updateAmazonRepricingSettings(inp: SaveAmazonRepricingSettingsInputType) {
  //   return this.doRequest<SaveAmazonRepricingSettingsInputType, SaveAmazonRepricingSettingsResultType>('update-amazon-repricing-settings', 'POST', inp);
  // }

  // ------------------------------------
  // Custom integration
  // ------------------------------------

  public async createCustomIntegration(inp: CreateCustomIntegrationInputType) {
    return this.doRequest<CreateCustomIntegrationInputType, CreateCustomIntegrationResultType>('create-custom-integration', 'POST', inp);
  }

  public async listCustomIntegration(inp: ListCustomIntegrationInputType) {
    return this.doRequest<ListCustomIntegrationInputType, ListCustomIntegrationResultType>('list-custom-integration', 'POST', inp);
  }

  public async deleteCustomIntegration(inp: DeleteCustomIntegrationInputType) {
    return this.doRequest<DeleteCustomIntegrationInputType, DeleteCustomIntegrationResultType>('delete-custom-integration', 'POST', inp);
  }

  public async updateCustomIntegration(inp: UpdateCustomIntegrationInputType) {
    return this.doRequest<UpdateCustomIntegrationInputType, UpdateCustomIntegrationResultType>('update-custom-integration', 'POST', inp);
  }

  public async searchCustomIntegration(inp) {
    return this.doRequest<SearchCustomIntegrationInputType, SearchCustomIntegrationResultType>('search-custom-integration', 'POST', inp);
  }

  // public async createCustomListingSingle(inp) {
  //   return this.doRequest<CreateScrapedCustomListingLambdaInput, CreateScrapedCustomListingLambdaResult>('create-custom-listing-single', 'POST', inp);
  // }

  public async updateCustomIntegrationStatus(inp) {
    return this.doRequest<UpdateCustomIntegrationStatusLambdaEnv, UpdateCustomIntegrationStatusResultType>('update-custom-integration-status', 'POST', inp);
  }

  public async exportCustomIntegration(inp) {
    return this.doRequest<ExportCustomIntegrationInputType, ExportCustomIntegrationLambdaResultType>('export-custom-integration', 'POST', inp);
  }

  public async exportCustomIntegrationListingTemplate() {
    return this.doRequest<ExportCustomIntegrationTemplateInputType, ExportCustomIntegrationTemplateLambdaResultType>('export-custom-integration-listing-template', 'POST');
  }

  // ------------------------------------
  // Facebook
  // ------------------------------------
  public async syncFacebookOrders(inp: SyncFacebookOrdersInputType) {
    return this.doRequest<SyncFacebookOrdersInputType, SyncFacebookOrdersResultType>('sync-facebook-orders', 'POST', inp);
  }

  public async getFacebookTrackingNumbers() {
    return this.doRequest('facebook-trackingnr-to-ship', 'POST', {});
  }

  public async setFacebookTrackingNrToShipped(inp) {
    return this.doRequest('facebook-mark-trackingnr-as-shipped', 'POST', inp);
  }

  // ------------------------------------
  // request utility - logging, authentication
  // ------------------------------------
  private async doRequest<I, R>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', body?: I, signal?: AbortSignal, credentials = 'same-origin'): Promise<R> {
    const url = process.env.REACT_APP_FRONTEND_API_URL + path.replace(/^\//, '');
    const idToken = this.getIdToken();
    const headers = idToken ? { Authorization: `${idToken}` } : undefined;
    const init = {
      credentials,
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers,
      signal
    };

    // const result = await this.tryDoRequest(url, init);
    const result = await pRetry(() => this.tryDoRequest(url, init), {
      onFailedAttempt: async (error) => {
        console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
        // if (['invalid', 'algorithm', 'signature', 'jwt', 'malformed'].some((m) => error.message?.toLowerCase().includes(m))) {
        //   console.log('error caught and now logout and redirect to login page', error.message);
        //   // await this.logoutAndRedirect();
        //   throw new Error('We\'re updating our systems. Please login again.');
        // }
        console.log('error NOT caught', error.name, error.message);
        if (['password', 'account'].some((m) => error.message?.toLowerCase().includes(m))) throw error;
      },
      retries: 1
    });

    return result as R;
  }

  private async tryDoRequest(url: string, init: any): Promise<any> {
    try {
      console.log('fetching request!', url);
      const response = await fetch(url, init);
      const result = await response.json();
      if (response.status > 299) {
        throw new Error((result && result.error) || `statuscode ${response.status}, message: ${JSON.stringify(result)}`);
      }
      return result;
    } catch (err) {
      console.error('api error', err);
      const session = await cognitoGetCurrentUserSession();
      if (session) {
        this.setAccessToken(session.getAccessToken().getJwtToken());
        const idToken = session.getIdToken().getJwtToken();
        this.setIdToken(idToken);
        const headers = idToken ? { Authorization: `${idToken}` } : undefined;
        // eslint-disable-next-line no-param-reassign
        init.headers = headers;
      }
      throw err;
    }
  }

  private async logoutAndRedirect() {
    await cognitoSignOut();
    localStorage.removeItem('username');
    localStorage.removeItem('user');
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    localStorage.removeItem('tags');
    window.location.href = `/login?redirect=${window.location.pathname + window.location.hash}`;
  }

  private setIdToken(idToken: string | undefined) {
    localStorage.setItem('idToken', idToken);
  }

  private getIdToken() {
    return localStorage.getItem('idToken') || undefined;
  }

  private setAccessToken(accessToken: string | undefined) {
    localStorage.setItem('accessToken', accessToken);
  }

  private getAccessToken() {
    return localStorage.getItem('accessToken') || undefined;
  }
}

export const api = new API();

export default api;
