import React, {
  Fragment,
  lazy, Suspense
} from 'react';
import {
  Redirect,
  Route, Switch
} from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/jwt-login',
    component: lazy(() => import('src/views/auth/AutoLoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/set-password',
    component: lazy(() => import('src/views/auth/SetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account/:tab',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/shopify',
        component: lazy(() => import('src/views/shopify'))
      },
      {
        exact: true,
        path: '/app/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      // {
      //   exact: true,
      //   path: '/app/orders/:orderId',
      //   component: lazy(() => import('src/views/order/OrderDetailsView'))
      // },
      {
        exact: true,
        path: '/app/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/products/uploads',
        component: lazy(() => import('src/views/product/UploadsView'))
      },
      {
        exact: true,
        path: '/app/products/downloads',
        component: lazy(() => import('src/views/product/DownloadsView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ],
  },
  // {
  //   exact: true,
  //   // guard: GuestGuard,
  //   path: '/tracking',
  //   component: lazy(() => import('src/views/TrackingNrs/TrackingNrsView')),
  // },
  {
    component: () => <Redirect to="/app/orders" />
  }
];

export default routes;
