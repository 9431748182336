import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const getUserPool = () => {
  const userPoolClientId = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID;
  const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;

  const poolData = {
    UserPoolId: userPoolId,
    ClientId: userPoolClientId,
  };
  const userPool = new CognitoUserPool(poolData);
  return userPool;
};

export const getCurrentCognitoUser = () => {
  const userPool = getUserPool();
  const cognitoUser = userPool.getCurrentUser();
  return cognitoUser;
};

export const getCognitoUser = (username: string) => {
  const userPool = getUserPool();
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return cognitoUser;
};
