/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden, Button,
  List,
  ListSubheader,
  makeStyles, SvgIcon, alpha
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import {
  Home as HomeIcon,
  List as ListIcon,
  DollarSign as DollarSignIcon,
  Plus as PlusIcon,
  UploadCloud as UploadIcon,
  Download as DownloadIcon,
  HelpCircle as HelpIcon,
  Radio as RadioIcon,
  Settings as SettingsIcon, Menu as MenuIcon, ArrowLeft, ArrowRight
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  onNavSizeToggle: () => void;
  openMobile: boolean;
  userIsInInventoryGroup: boolean;
  userIsInOrderGroup: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  className?: string;
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const bottomSection: Section[] = [
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Settings',
        icon: SettingsIcon,
        href: '/app/account'
      }
    ]
  },
  {
    subheader: 'Affiliates',
    items: [
      {
        title: 'Affiliates',
        icon: RadioIcon,
        href: 'https://oagenius.tapfiliate.com/'
      }
    ]
  },
  {
    subheader: 'Help',
    items: [
      {
        title: 'Help Center',
        icon: HelpIcon,
        href: 'https://oagenius.zendesk.com/hc/en-us/categories/360005061711-Sage-for-Walmart-Inventory-and-Order-Management-'
      }
    ]
  }
];

function reduceChildRoutes({
  acc,
  pathname,
  item,
  isNavMinimized,
  depth
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
  isNavMinimized?: boolean;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        className={item.className || ''}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        isNavMinimized={Boolean(isNavMinimized)}
        title={item.title}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        className={item.className || ''}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        isNavMinimized={Boolean(isNavMinimized)}
      />
    );
  }

  return acc;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
  isNavMinimized
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  isNavMinimized?: boolean;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc, item, pathname, depth, isNavMinimized
        }),
        []
      )}
    </List>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    boxShadow: '0 0 15px 0 rgb(34 41 47 / 5%)',
    border: 'none',
  },
  mobileDrawer: {
    width: 'auto',
  },
  desktopDrawer: {
    width: 250, // 256
    top: 64,
    height: 'calc(100% - 64px)',
    transition: 'width .3s ease',
  },
  desktopDrawerNavMini: {
    top: 64,
    height: 'calc(100% - 64px)',
    width: 60,
    border: 'none',
    transition: 'width .3s ease'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  subMenu: {
    '& ul li:not(:last-child)': {
      marginBottom: 8,
    }
  },
  btnClose: {
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 2,
  },
  btnCloseContainer: {
    width: 'auto',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-end',
    padding: '10px',
    transition: 'width .3s ease',
    '& button': {
      width: '100%',
      padding: '10px 8px',
      alignContent: 'center',
      justifyContent: 'flex-end',
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    }
  },
  btnCloseContainerSmall: {
    width: 60,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-end',
    padding: '10px 5px',
    transition: 'width .3s ease',
    '& button': {
      width: '100%',
      minWidth: 50,
      padding: '10px 3px',
      alignContent: 'center',
      justifyContent: 'center',
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    }
  },
  inventoryLink: {
    '& a svg line:nth-child(2)': {
      transform: 'scaleX(0.75)',
      transformOrigin: '8px',
    },
    '& a svg line:nth-child(3)': {
      transform: 'scaleX(0.85)',
      transformOrigin: '8px',
    }
  },
  navBar: {
    padding: '10px',
  },
  navBarMini: {
    padding: '10px 5px',
  }
}));

const NavBar: FC<NavBarProps> = ({
  onMobileClose, openMobile, onNavSizeToggle, userIsInInventoryGroup, userIsInOrderGroup,
}) => {
  const classes = useStyles();
  const location = useLocation();

  const sections: Section[] = [
    /*  {
        subheader: '',
        items: [
          {
            title: 'Dashboard',
            icon: HomeIcon,
            href: '/app/reports/dashboard-alternative'
          }
        ]
      }, */
    {
      subheader: 'Orders',
      items: [
        {
          title: 'Orders',
          icon: DollarSignIcon,
          href: '/app/orders'
        },
      ]
    },
    {
      subheader: 'Products',
      items: [
        {
          title: 'Inventory',
          icon: ListIcon,
          href: '/app/products',
          className: classes.inventoryLink
        },
        {
          title: 'Create Listing',
          icon: PlusIcon,
          href: '/app/products/create'
        },
        {
          title: 'Uploads',
          icon: UploadIcon,
          href: '/app/products/uploads'
        },
        {
          title: 'Downloads',
          icon: DownloadIcon,
          href: '/app/products/downloads'
        },
      ]
    },
    // {
    //   subheader: 'Applications',
    //   items: [
    //     {
    //       title: 'Projects Platform',
    //       href: '/app/projects',
    //       icon: BriefcaseIcon,
    //       items: [
    //         {
    //           title: 'Overview',
    //           href: '/app/projects/overview'
    //         },
    //         {
    //           title: 'Browse Projects',
    //           href: '/app/projects/browse'
    //         },
    //         {
    //           title: 'Create Project',
    //           href: '/app/projects/create'
    //         },
    //         {
    //           title: 'View Project',
    //           href: '/app/projects/1'
    //         }
    //       ]
    //     },
    //     {
    //       title: 'Social Platform',
    //       href: '/app/social',
    //       icon: ShareIcon,
    //       items: [
    //         {
    //           title: 'Profile',
    //           href: '/app/social/profile'
    //         },
    //         {
    //           title: 'Feed',
    //           href: '/app/social/feed'
    //         }
    //       ]
    //     },
    //     {
    //       title: 'Kanban',
    //       href: '/app/kanban',
    //       icon: TrelloIcon
    //     },
    //     {
    //       title: 'Mail',
    //       href: '/app/mail',
    //       icon: MailIcon
    //     },
    //     {
    //       title: 'Chat',
    //       href: '/app/chat',
    //       icon: MessageCircleIcon,
    //       info: () => (
    //         <Chip
    //           color="secondary"
    //           size="small"
    //           label="Updated"
    //         />
    //       )
    //     },
    //     {
    //       title: 'Calendar',
    //       href: '/app/calendar',
    //       icon: CalendarIcon,
    //       info: () => (
    //         <Chip
    //           color="secondary"
    //           size="small"
    //           label="Updated"
    //         />
    //       )
    //     }
    //   ]
    // },
    // {
    //   subheader: 'Auth',
    //   items: [
    //     {
    //       title: 'Login',
    //       href: '/login-unprotected',
    //       icon: LockIcon
    //     },
    //     {
    //       title: 'Register',
    //       href: '/register-unprotected',
    //       icon: UserPlusIcon
    //     }
    //   ]
    // },
    // {
    //   subheader: 'Pages',
    //   items: [
    //     {
    //       title: 'Account',
    //       href: '/app/account',
    //       icon: UserIcon
    //     },
    //     {
    //       title: 'Error',
    //       href: '/404',
    //       icon: AlertCircleIcon
    //     },
    //     {
    //       title: 'Pricing',
    //       href: '/pricing',
    //       icon: DollarSignIcon
    //     }
    //   ]
    // },
    // {
    //   subheader: 'Extra',
    //   items: [
    //     {
    //       title: 'Charts',
    //       href: '/app/extra/charts',
    //       icon: BarChartIcon,
    //       items: [
    //         {
    //           title: 'Apex Charts',
    //           href: '/app/extra/charts/apex'
    //         }
    //       ]
    //     },
    //     {
    //       title: 'Forms',
    //       href: '/app/extra/forms',
    //       icon: EditIcon,
    //       items: [
    //         {
    //           title: 'Formik',
    //           href: '/app/extra/forms/formik'
    //         },
    //         {
    //           title: 'Redux Forms',
    //           href: '/app/extra/forms/redux'
    //         },
    //       ]
    //     },
    //     {
    //       title: 'Editors',
    //       href: '/app/extra/editors',
    //       icon: LayoutIcon,
    //       items: [
    //         {
    //           title: 'DraftJS Editor',
    //           href: '/app/extra/editors/draft-js'
    //         },
    //         {
    //           title: 'Quill Editor',
    //           href: '/app/extra/editors/quill'
    //         }
    //       ]
    //     }
    //   ]
    // }
  ];

  const [isNavMinimized, toggleIsNavMinimized] = useState(localStorage.getItem('is_nav_minimized') === 'true');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleNavMinimized = () => {
    localStorage.setItem('is_nav_minimized', `${!isNavMinimized}`);
    toggleIsNavMinimized(!isNavMinimized);
    onNavSizeToggle();
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box> */}
        {/* <Divider /> */}
        <Box className={`${classes.navBar} ${isNavMinimized ? classes.navBarMini : ''}`}>
          {sections.filter((section) => {
            if (section.subheader === 'Orders' && userIsInInventoryGroup && !userIsInOrderGroup) {
              return false;
            }
            if (section.subheader === 'Products' && !userIsInInventoryGroup && userIsInOrderGroup) {
              return false;
            }
            return true;
          }).map((section) => (
            <List
              className={classes.subMenu}
              key={section.subheader}
              subheader={(
                <>
                  <Hidden mdDown>
                    <ListSubheader
                      disableGutters
                      disableSticky
                    />
                  </Hidden>
                  <Hidden lgUp>
                    <ListSubheader
                      disableGutters
                      disableSticky
                    />
                  </Hidden>
                </>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                isNavMinimized
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box
          className={`${classes.navBar} ${isNavMinimized ? classes.navBarMini : ''}`}
          position="absolute"
          bottom="0"
          width="100%"
        >
          {bottomSection.filter((section) => {
            if (section.subheader === 'Settings' && (userIsInInventoryGroup && userIsInOrderGroup)) {
              return false;
            }
            return true;
          }).map((section) => (
            <List
              key={section.subheader}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                isNavMinimized
              })}
            </List>
          ))}
        </Box>
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
      <Hidden mdDown>
        {isNavMinimized ? (
          <Box className={classes.btnCloseContainerSmall}>
            <Button
              color="secondary"
              className={classes.btnClose}
              onClick={handleNavMinimized}
            >
              <SvgIcon fontSize="small">
                <ArrowRight />
              </SvgIcon>
            </Button>
          </Box>
        ) : (
          <Box className={classes.btnCloseContainer}>
            <Button
              color="secondary"
              className={classes.btnClose}
              onClick={handleNavMinimized}
            >
              <SvgIcon fontSize="small">
                <ArrowLeft />
              </SvgIcon>
            </Button>
          </Box>
        )}
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {isNavMinimized ? (
        <Hidden mdDown>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawerNavMini }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
      ) : (
        <Hidden mdDown>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer, }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onNavSizeToggle: PropTypes.func,
};
NavBar.defaultProps = {
  onNavSizeToggle: () => { }
};

export default NavBar;
