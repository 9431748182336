export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  // UNICORN: 'UNICORN'
};

export const MAX_HANDLING_DAYS = 10;
export const MAX_LATEST_SHIP_DATE_DAYS = 5;
