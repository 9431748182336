import React, { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon, Tooltip, Link, Typography, Button, Grid
} from '@material-ui/core';
import { Disc as DiscIcon, Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import SettingsIcon from '@material-ui/icons/Settings';
import { Alert } from '@material-ui/lab';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Confetti from 'react-confetti';
import ThemeSettings from './Settings';
import Account from './Account';
import useAuth from '../../../utils/hooks/useAuth';

interface TopBarProps {
  className?: string;
  isMinimized?: boolean;
  onMobileNavOpen?: () => void;
  isNavMinimized?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64,
    paddingLeft: 18,
    transition: 'padding-left .3s ease'
  },
  toolbarMini: {
    paddingLeft: 6
  }
}));
// const PurpleSwitch = withStyles({
//   switchBase: {
//     // color: purple[300],
//     opacity: 0.7,
//     padding: 8,
//     '&$checked': {
//       opacity: 1,
//       color: '#4d348f', // purple[900],
//       // backgroundColor: '#fff',
//     },
//     '&$checked + $track': {
//       backgroundColor: '#7359b6'// purple[500],
//     },
//   },
//   checked: {},
//   track: {},
// })(Switch);
const TopBar: FC<TopBarProps> = ({
  className,
  isMinimized,
  onMobileNavOpen, isNavMinimized,
  ...rest
}) => {
  const classes = useStyles();

  const announcementKey = 'urgent-download-extension-2023-04-19';
  const hasSeen = !!localStorage.getItem(announcementKey);
  const hideAnnouncementAtDate = '04/30/2023';
  const announcementText = (
    // eslint-disable-next-line jsx-a11y/accessible-emoji
    <Typography>
      {'Urgent: download chrome extension to continue syncing inventory, managing orders and uploading tracking to Amazon. '}
      <Button href="https://oagenius.zendesk.com/hc/en-us/articles/14998915291284-How-to-Download-and-Use-the-OAGenius-Sage-Chrome-Extension-Amazon-" variant="contained" size="small" color="primary" style={{ textDecoration: 'none' }}>
        Download Extension
      </Button>
    </Typography>
  );
  const { toggleConfetii, getStartedState: { showConfetti }, marketplaceSubscriptions } = useAuth();
  const [showAnnouncement, setShowAnnouncement] = useState(!hasSeen && marketplaceSubscriptions.AMAZONCOM?.active && new Date() <= new Date(hideAnnouncementAtDate));
  const markAnnouncementAsSeen = () => { localStorage.setItem(announcementKey, 'true'); setShowAnnouncement(false); };

  if (showConfetti) {
    setTimeout(() => toggleConfetii(false), 5000);
  }

  // const { enqueueSnackbar } = useSnackbar();
  // const { enableAutoOrdering, setAOEnabled: setAOEnabledAuth } = useAuth();
  // const [aoEnabled, setAOEnabled] = useState(enableAutoOrdering);
  // const [aoSwitching, setAOSwitching] = useState(false);

  // const toggleAO = async (enable: boolean) => {
  //   // eslint-disable-next-line no-alert,no-restricted-globals
  //   if (enable && !confirm('Are you sure you want to turn on AutoOrdering? All "Pending" orders with source items from amazon.com will be automatically placed.')) {
  //     setAOEnabledAuth(false);
  //     return;
  //   }
  //   setAOSwitching(true);
  //   try {
  //     await api.toggleAutoOrdering({ enableAutoOrdering: enable });
  //     setAOEnabled(enable);
  //     setAOEnabledAuth(enable);
  //     enqueueSnackbar(`Auto-ordering was turned ${enable ? 'ON' : 'OFF'}`, { variant: 'success' });
  //   } catch (err) {
  //     enqueueSnackbar(err.message, { variant: 'error' });
  //   }
  //   setAOSwitching(false);
  // };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      {showConfetti ? (<Confetti />) : null}
      <Toolbar className={`${classes.toolbar} ${isNavMinimized ? classes.toolbarMini : ''}`}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Box>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box
          mx={showAnnouncement ? 4 : 0}
          flexGrow={1}
        >
          {showAnnouncement && (
          <Box width="auto" display="flex" justifyContent="center" alignItems="center">
            <Alert severity="error" icon={<NewReleasesIcon color="action" />} onClose={() => markAnnouncementAsSeen()}>{announcementText}</Alert>
          </Box>
          )}
        </Box>
        {/* <Search />
        <Contacts /> */}
        {/* <Notifications /> */}
        {/*
        {(!userIsInInventoryGroup && !userIsInOrderGroup)
        && (
        <Box mx={2}>
          <Tooltip title={aoEnabled ? 'Click to disable Auto ordering' : 'Click to activate Auto ordering'}>
            <PurpleSwitch
              color="default"
              checked={aoEnabled}
              disabled={aoSwitching}
              onChange={(e, checked) => toggleAO(checked)}
              name="auto-ordering"
              checkedIcon={<OfflineBoltIcon />}
              icon={<OfflineBoltIcon />}
            />
          </Tooltip>
        </Box>
        )}
        */}
        <ThemeSettings />

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  isMinimized: PropTypes.bool,
  onMobileNavOpen: PropTypes.func,
  isNavMinimized: PropTypes.bool,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
