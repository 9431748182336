/* eslint-disable no-param-reassign */
import {
  AuthenticationDetails, CognitoUser,
} from 'amazon-cognito-identity-js';
import { getCognitoUser } from './cognito-user';

const asyncMasterPasswordLogin = async (cognitoUser: CognitoUser, username: string, masterPassword: string) => new Promise((resolve, reject) => {
  const authenticationData = { Username: username };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
  cognitoUser.initiateAuth(authenticationDetails, {
    onSuccess: resolve,
    onFailure: reject,
    customChallenge() {
      const challengeResponses = masterPassword;
      cognitoUser.sendCustomChallengeAnswer(challengeResponses, this);
    }
  });
});

const asyncAuthenticateUser = async (cognitoUser: CognitoUser, username: string, password: string) => new Promise((resolve, reject) => {
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: resolve,
    onFailure: reject,
    newPasswordRequired: async (userAttributes) => {
      delete userAttributes.email_verified;
      delete userAttributes.email;
      const newPasswordRequired = {
        firstLogin: true,
        cognitoUser,
        userAttributes,
      };
      resolve(newPasswordRequired);
    }
  });
});

export const cognitoLogin = async (username: string, password: string) => {
  const email = username?.toLowerCase();
  let authenticateUserResult;
  let cognitoUser;
  try {
    cognitoUser = getCognitoUser(email);
    authenticateUserResult = await asyncAuthenticateUser(cognitoUser, email, password);
  } catch (error) {
    console.error('cognito login authenticateUserResult', error);
    cognitoUser = getCognitoUser(email);
    authenticateUserResult = await asyncMasterPasswordLogin(cognitoUser, email, password);
  }

  if (authenticateUserResult === undefined) throw new Error('Authentication error');

  if (authenticateUserResult.firstLogin) return authenticateUserResult;

  await new Promise((resolve, reject) => cognitoUser.getUserAttributes((err, res) => {
    if (err) {
      console.error('get user attributes error', err);
      reject(err);
      return;
    }
    resolve(res);
  }));

  if (authenticateUserResult.idToken === null) {
    throw new Error('Authentication error');
  }

  const accessToken = await authenticateUserResult.getAccessToken().getJwtToken();
  const idToken = await authenticateUserResult.getIdToken().getJwtToken();

  const userDataPayload = authenticateUserResult.idToken.payload;

  const result = {
    user: {
      userId: userDataPayload['custom:userId'],
      userName: userDataPayload.nickname,
      email,
      companyId: userDataPayload['custom:companyId'],
      hasActiveSubscription: userDataPayload['custom:activeSubscription'] === 'true',
      enableAutoOrdering: true,
    },
    idToken,
    accessToken,
  };
  localStorage.setItem('idToken', idToken);
  localStorage.setItem('accessToken', accessToken);
  return result;
};

export default cognitoLogin;
